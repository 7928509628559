






























































//@import url(); 引入公共css类
@media (min-width: 1620px) {
  .container {
    max-width: 1620px;
  }
}
.header {
  background: linear-gradient(90deg, #2d5fcd 0, #7b5ab6 100%), #2839a0;
  button {
    border-radius: 2rem;
    margin-top: 3rem;
    padding: 14px 33px;
    text-transform: uppercase;
    font-size: 16px;
    transition: 1s;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #409eff;
    }
  }
}

.mode {
  position: relative;
  overflow: hidden;
  img {
    height: 170px;
    width: 100%;
    display: block;
  }
  .description {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -100%;
    left: 0;
    border-radius: 4px;
    padding: 22px 10px;
    padding: 20px;
    background-color: rgba($color: #000, $alpha: 0.5);
    color: #fff;
    transition: all 1s;
  }
  &:hover {
    .description {
      bottom: 0;
    }
  }
  &.last {
    line-height: 224px;
    text-align: center;
    border: 1px solid;
    color: #409eff;
  }
}
